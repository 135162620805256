var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "add-currency-container" },
      [
        _vm.getCurrenciesAvailable
          ? _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newCurrency,
                    expression: "newCurrency",
                  },
                ],
                staticClass: "input-box",
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.newCurrency = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "", disabled: "" } }, [
                  _vm._v(" -- select an option -- "),
                ]),
                _vm._l(_vm.getCurrenciesAvailable, function (option) {
                  return _c(
                    "option",
                    { key: option.currencyCode, domProps: { value: option } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(option.name) +
                          " - " +
                          _vm._s(option.currencyCode) +
                          " - " +
                          _vm._s(option.symbol) +
                          " "
                      ),
                    ]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        _c("BaseButton", {
          attrs: {
            buttonClass: "alt-btn-two",
            disabled: !_vm.enableAddCurrencyButton,
          },
          on: {
            click: function ($event) {
              return _vm.addCurrency()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [
                  _c("font-awesome-icon", {
                    staticClass: "action-icon",
                    attrs: { icon: ["fal", "plus"] },
                  }),
                  _vm._v("  new currency"),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _vm.currenciesSelected.length
      ? _c("table", [
          _vm._m(0),
          _c(
            "tbody",
            _vm._l(_vm.currenciesSelected, function (currencySelected) {
              return _c("tr", { key: currencySelected.currencyCode }, [
                _c("td", [_vm._v(_vm._s(currencySelected.name))]),
                _c("td", [_vm._v(_vm._s(currencySelected.currencyCode))]),
                _c("td", [_vm._v(_vm._s(currencySelected.symbol))]),
                _c(
                  "td",
                  { staticClass: "text-right actions" },
                  [
                    _c("BaseButton", {
                      attrs: { type: "button", tooltipText: "Delete" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteCurrency(currencySelected)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  staticClass: "action-icon",
                                  attrs: { icon: ["fal", "trash-alt"] },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm.currenciesSelected.length === 3
      ? _c("div", { staticClass: "info-message" }, [
          _vm._v("You can add up to 3 currencies."),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-left w-40" }, [_vm._v("Name")]),
        _c("th", { staticClass: "text-left w-20" }, [_vm._v("Code")]),
        _c("th", { staticClass: "text-left w-20" }, [_vm._v("Symbol")]),
        _c("th", { staticClass: "w-20" }, [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }