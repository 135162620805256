var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "background",
      class: { "bottom-padding": !_vm.isInitialSetupPage },
    },
    [
      _vm.showUnSavedMessage
        ? _c("BaseModal", {
            class: { "show-modal": _vm.showUnSavedMessage },
            on: { close: _vm.closeUnsavedPopUp },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("UnSavedMessage", {
                        on: {
                          cancelMove: _vm.closeUnsavedPopUp,
                          saveChanges: _vm.saveData,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              721083903
            ),
          })
        : _vm._e(),
      _vm.isFetchingData
        ? _c("LoadingOverlay", {
            attrs: { "is-fetching-data": _vm.isFetchingData },
          })
        : [
            _vm.isInitialSetupPage
              ? _c("h3", { staticClass: "first-setup-sentence" }, [
                  _vm._v(
                    " Please confirm and complete the below client setup in order to access your Monitor setup. Client status and type will be available to edit under the General Settings tab, base currency can be changed by contacting the Monitor support team. "
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "label-container-center" }, [
              _c(
                "label",
                { staticClass: "label-text", attrs: { for: "client-status" } },
                [_vm._v("Client status:")]
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.updatedClientSetup.clientStatus,
                      expression: "updatedClientSetup.clientStatus",
                    },
                  ],
                  staticClass: "input-box",
                  class: { "invalid-input": !_vm.isClientStatusValid },
                  attrs: { id: "client-status" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.updatedClientSetup,
                        "clientStatus",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(
                  _vm.clientSetupOptions.clientStatusOptions,
                  function (clientStatus) {
                    return _c(
                      "option",
                      {
                        key: clientStatus,
                        attrs: { label: clientStatus },
                        domProps: { value: clientStatus },
                      },
                      [_vm._v(_vm._s(clientStatus))]
                    )
                  }
                ),
                0
              ),
            ]),
            _vm.updatedClientSetup.clientStatus === "Inactive"
              ? _c("span", { staticClass: "info-message" }, [
                  _vm._v("This setup will no longer appear on Pending."),
                ])
              : _vm._e(),
            !_vm.isClientStatusValid
              ? _c("span", { staticClass: "warning-message" }, [
                  _vm._v("Please select a valid client status."),
                ])
              : _vm._e(),
            _c("div", { staticClass: "label-container-center" }, [
              _c(
                "label",
                { staticClass: "label-text", attrs: { for: "client-type" } },
                [_vm._v("Client type:")]
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.updatedClientSetup.clientType,
                      expression: "updatedClientSetup.clientType",
                    },
                  ],
                  staticClass: "input-box",
                  class: { "invalid-input": !_vm.isClientTypeValid },
                  attrs: { id: "client-type" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.updatedClientSetup,
                        "clientType",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(
                  _vm.clientSetupOptions.clientTypeOptions,
                  function (clientType) {
                    return _c(
                      "option",
                      {
                        key: clientType,
                        attrs: { label: clientType },
                        domProps: { value: clientType },
                      },
                      [_vm._v(_vm._s(clientType))]
                    )
                  }
                ),
                0
              ),
            ]),
            !_vm.isClientTypeValid
              ? _c("span", { staticClass: "warning-message" }, [
                  _vm._v("Please select a valid client type."),
                ])
              : _vm._e(),
            !_vm.isInitialSetupPage
              ? _c("div", { staticClass: "label-container-center" }, [
                  _c(
                    "span",
                    { staticClass: "label-text" },
                    [
                      _vm._v(" Research monitoring level "),
                      _c("font-awesome-icon", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value:
                              "States whether portfolio holdings are displayed on the Investment Research website. <br> This setting is determined by client type and status.",
                            expression:
                              "'States whether portfolio holdings are displayed on the Investment Research website. <br> This setting is determined by client type and status.'",
                          },
                        ],
                        staticClass: "action-icon",
                        attrs: { icon: ["fal", "info-circle"] },
                      }),
                      _vm._v(":"),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { staticClass: "input-box single-option disabled" },
                    [
                      _vm._v(
                        _vm._s(_vm.updatedClientSetup.researchMonitoringLevel)
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("div", [
              _c("span", { staticClass: "flex label-container-center" }, [
                _c(
                  "label",
                  {
                    class: { "bottom-padding": _vm.isInitialSetupPage },
                    attrs: { for: "base-portfolio-currency label-text" },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "label-text" },
                      [
                        _vm._v(" Base portfolio currency "),
                        _c("font-awesome-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.baseCurrencyTooltip,
                              expression: "baseCurrencyTooltip",
                            },
                          ],
                          staticClass: "action-icon",
                          attrs: { icon: ["fal", "info-circle"] },
                        }),
                        _vm._v(": "),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.clientSetupOptions.baseCurrencyOptions.length > 1
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.updatedClientSetup.baseCurrency,
                            expression: "updatedClientSetup.baseCurrency",
                          },
                        ],
                        staticClass: "input-box",
                        attrs: { id: "base-portfolio-currency" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.updatedClientSetup,
                              "baseCurrency",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(
                        _vm.clientSetupOptions.baseCurrencyOptions,
                        function (currency) {
                          return _c(
                            "option",
                            {
                              key: currency.currencyCode,
                              domProps: { value: currency },
                            },
                            [
                              _vm._v(
                                _vm._s(currency.name) +
                                  " - " +
                                  _vm._s(currency.currencyCode) +
                                  " - " +
                                  _vm._s(currency.symbol)
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _c("span", { staticClass: "input-box single-option" }, [
                      _vm._v(
                        _vm._s(_vm.updatedClientSetup.baseCurrency.name) +
                          " - " +
                          _vm._s(
                            _vm.updatedClientSetup.baseCurrency.currencyCode
                          ) +
                          " - " +
                          _vm._s(_vm.updatedClientSetup.baseCurrency.symbol)
                      ),
                    ]),
              ]),
            ]),
            !_vm.isInitialSetupPage
              ? _c("div", { staticClass: "label-container-center" }, [
                  _c(
                    "span",
                    { staticClass: "label-text" },
                    [
                      _vm._v(" Additional reporting currencies"),
                      _c("font-awesome-icon", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value:
                              "Additional currencies to display Pending portfolio in.<br> The options on the live portfolio will not be updated until a push to live is made.",
                            expression:
                              "'Additional currencies to display Pending portfolio in.<br> The options on the live portfolio will not be updated until a push to live is made.'",
                          },
                        ],
                        staticClass: "action-icon",
                        attrs: { icon: ["fal", "info-circle"] },
                      }),
                      _vm._v(":"),
                    ],
                    1
                  ),
                  _vm.clientSetupOptions.additionalReportingCurrencyOptions
                    .length > 0
                    ? _c(
                        "div",
                        { staticClass: "additional-currencies" },
                        [
                          _c("AdditionalReportingCurrencies", {
                            attrs: {
                              currencyOptions:
                                _vm.clientSetupOptions
                                  .additionalReportingCurrencyOptions,
                              currenciesSelected:
                                _vm.updatedClientSetup
                                  .additionalReportingCurrencies,
                            },
                            on: {
                              "remove-additional-currency":
                                _vm.removeAdditionalCurrency,
                              "add-additional-currency":
                                _vm.addAdditionalCurrency,
                            },
                          }),
                        ],
                        1
                      )
                    : _c("div", [
                        _c(
                          "span",
                          { staticClass: "no-additional-currencies-text" },
                          [
                            _vm._v(
                              "There are no additional reporting currencies available for your selected base currency"
                            ),
                          ]
                        ),
                      ]),
                ])
              : _vm._e(),
            !_vm.isInitialSetupPage
              ? _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "label-container-center bottom-padding" },
                    [
                      _c(
                        "span",
                        { staticClass: "label-text" },
                        [
                          _vm._v(" Send data to Advance? "),
                          _c("font-awesome-icon", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.advanceDataTooltip,
                                expression: "advanceDataTooltip",
                              },
                            ],
                            staticClass: "action-icon",
                            attrs: { icon: ["fal", "info-circle"] },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "radio-buttons" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.updatedClientSetup.sendDataToAdvance,
                          expression: "updatedClientSetup.sendDataToAdvance",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "yes",
                        name: "discussed",
                        disabled: !_vm.clientSetupOptions.canSendDataToAdvance,
                      },
                      domProps: {
                        value: true,
                        checked: _vm._q(
                          _vm.updatedClientSetup.sendDataToAdvance,
                          true
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.updatedClientSetup,
                            "sendDataToAdvance",
                            true
                          )
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "yes" } }, [_vm._v("Yes")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.updatedClientSetup.sendDataToAdvance,
                          expression: "updatedClientSetup.sendDataToAdvance",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "no",
                        name: "discussed",
                        disabled: !_vm.clientSetupOptions.canSendDataToAdvance,
                      },
                      domProps: {
                        value: false,
                        checked: _vm._q(
                          _vm.updatedClientSetup.sendDataToAdvance,
                          false
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.updatedClientSetup,
                            "sendDataToAdvance",
                            false
                          )
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "no" } }, [_vm._v("No")]),
                  ]),
                ])
              : _vm._e(),
            !_vm.isInitialSetupPage
              ? _c(
                  "div",
                  {
                    staticClass: "save-slide-out",
                    class: { "show-save": _vm.isDataToBeSaved },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "slide-out-inner flex flex--row-center" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "btn btn--discard",
                            on: { click: _vm.discardChanges },
                          },
                          [_vm._v("Discard")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "btn btn--default",
                            on: { click: _vm.saveData },
                          },
                          [_vm._v("Save")]
                        ),
                      ]
                    ),
                  ]
                )
              : _c(
                  "div",
                  { staticClass: "save-button-container" },
                  [
                    _c(
                      "BaseButton",
                      {
                        staticClass: "primary-btn",
                        attrs: { type: "button" },
                        on: { click: _vm.saveData },
                      },
                      [_vm._v("Save")]
                    ),
                  ],
                  1
                ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }