










































































































import clientSetupApi from '@/api/clientSetupApi';
import { IClientSetupDto, ICurrencyOptionDto } from '@/interfaces/dto/IClientSetupWithOptionsDto';
import { IUpdateClientSetupDto } from '@/interfaces/dto/IUpdateClientSetupDto';
import { IClientSetupOptionsDto } from '@/interfaces/dto/IClientSetupWithOptionsDto';
import { IClientSetupWithOptionsDto } from '@/interfaces/dto/IClientSetupWithOptionsDto';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseButton from '@/components/BaseElements/BaseButton.vue';
import BaseModal from '@/components/BaseModal.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import UnSavedMessage from '@/components/MonitorManager/UnSavedMessage.vue';
import { cloneDeep } from 'lodash';
import AdditionalReportingCurrencies from '@/components/MonitorManager/AdditionalReportingCurrencies.vue';

@Component({
    components: {
        LoadingOverlay,
        BaseButton,
        BaseModal,
        UnSavedMessage,
        AdditionalReportingCurrencies
    }
})

export default class ClientSetupView extends Vue {
    @Prop({ type: Boolean, required: false, default: false }) public isInitialSetupPage: boolean;

    @Prop({ type: [Object, null], required: false, default: null }) public clientSetupWithOptions: IClientSetupWithOptionsDto | null;

    created (): void {
        this.fetchData();
    }

    public clientSetupOptions: IClientSetupOptionsDto = null;

    public initialClientSetup: IClientSetupDto = null;

    public updatedClientSetup: IClientSetupDto = null;

    public NOT_SET = 'Not Set';

    public isFetchingData = true;

    //Validation booleans

    public isClientTypeValid = true;

    public isClientStatusValid = true;

    private async fetchData (): Promise<void> {
        this.isFetchingData = true;

        if (this.unsavedData) {
            this.$store.commit('updateShowUnSavedMessage', true);
            return;
        }

        if (this.clientSetupWithOptions !== null){
            this.initialClientSetup = this.clientSetupWithOptions.clientSetup;
            this.clientSetupOptions = this.clientSetupWithOptions.clientSetupOptions;
        } else {
            if (this.$store.state.selectedScheme) {
                try{
                    const apiResult = await this.getClientSetup();
                    this.initialClientSetup = apiResult.clientSetup;
                    this.clientSetupOptions = apiResult.clientSetupOptions;
                } catch{
                    this.$store.dispatch('pushNetworkErrorMessage', 'Error fetching client setup. Please contact the Monitor support team.');
                };
            } else {
                this.$router.push('/');
            }
        }

        this.updatedClientSetup = cloneDeep(this.initialClientSetup);
        this.isFetchingData = false;
    }

    private async getClientSetup () : Promise<IClientSetupWithOptionsDto> {
        const clientSetup = await clientSetupApi.getClientSetupForClient(this.$store.state.selectedScheme.clientEntityID);
        return clientSetup;
    }

    get baseCurrencyTooltip (): string {
        let tooltip = 'Default currency on Pending, all market and exposure values on Pro are assumed to be in this currency.';

        if (this.clientSetupOptions.baseCurrencyOptions.length < 2) {
            tooltip += '<br>Please contact the Monitor support team if the base portfolio currency needs to be changed.';
        }

        return tooltip;
    }

    get advanceDataTooltip (): string {
        let tooltip = 'Data from live portfolio sent daily to the equivalent Advance setup.';

        if (!this.clientSetupOptions.canSendDataToAdvance) {
            tooltip += '<br>Please push the Pending portfolio live in order to send data to Advance.';
        }

        return tooltip;
    }

    get showUnSavedMessage (): boolean {
        return this.$store.state.showUnSavedMessage;
    }

    get unsavedData (): boolean {
        return this.$store.state.unsavedData;
    }

    public closeUnsavedPopUp (): void {
        this.$store.commit('updateShowUnSavedMessage', false);
    }

    @Watch('isDataToBeSaved')
    private setUnsavedData (isDataToBeSaved: boolean) {
        this.$store.commit('updateUnsavedData', isDataToBeSaved);
    }

    get isDataToBeSaved (): boolean {
        const updated = this.updatedClientSetup;
        const initial = this.initialClientSetup;

        if (updated === null) return false;

        this.setResearchMonitoringLevel(updated);

        return updated.clientStatus !== initial.clientStatus ||
            updated.clientType !== initial.clientType ||
            updated.researchMonitoringLevel !== initial.researchMonitoringLevel ||
            updated.baseCurrency.currencyCode !== initial.baseCurrency.currencyCode ||
            !this.arraysContainSameValues(initial.additionalReportingCurrencies, updated.additionalReportingCurrencies) ||
            updated.sendDataToAdvance !== initial.sendDataToAdvance;
    }

    private setResearchMonitoringLevel (updatedClientSetup: IClientSetupDto) {
        if (updatedClientSetup.clientType === 'Corporate DB' || updatedClientSetup.clientStatus !== 'Client') {
            this.updatedClientSetup.researchMonitoringLevel = 'None';
        } else {
            this.updatedClientSetup.researchMonitoringLevel = 'Standard';
        }
    }

    public async saveData (): Promise<void> {
        this.isFetchingData = true;

        this.$store.commit('updateShowUnSavedMessage', false);
        this.$store.commit('updateUnsavedData', false);

        const isValid = this.validateUpdatedClientSetup();

        if (!isValid){
            this.isFetchingData = false;
            return;
        }

        try{
            const clientSetupRequest : IUpdateClientSetupDto = {
                clientStatus: this.updatedClientSetup.clientStatus,
                clientType: this.updatedClientSetup.clientType,
                researchMonitoringLevel: this.updatedClientSetup.researchMonitoringLevel,
                baseCurrency: this.updatedClientSetup.baseCurrency.currencyCode,
                additionalReportingCurrencies: this.updatedClientSetup.additionalReportingCurrencies.map(x => x.currencyCode),
                sendDataToAdvance: this.updatedClientSetup.sendDataToAdvance,
            };
            const response = await clientSetupApi.updateClientSetupForClient(this.$store.state.selectedScheme.clientEntityID, clientSetupRequest);
            this.initialClientSetup = response;
            this.updatedClientSetup = cloneDeep(this.initialClientSetup);
        } catch {
            this.$store.dispatch('pushNetworkErrorMessage', 'Error saving client setup. Please try again later.');
        }

        if (this.isInitialSetupPage) {
            this.$emit('close-client-setup');
        }

        this.isFetchingData = false;
    }

    private validateUpdatedClientSetup (): boolean {
        this.isClientStatusValid = this.updatedClientSetup.clientStatus !== this.NOT_SET;
        this.isClientTypeValid = this.updatedClientSetup.clientType !== this.NOT_SET;

        return this.isClientStatusValid && this.isClientTypeValid;
    }

    private arraysContainSameValues (arr1: ICurrencyOptionDto[], arr2: ICurrencyOptionDto[]): boolean {
        if (arr1.length !== arr2.length) {
            return false;
        }

        const sortedArr1 = arr1.slice().sort();
        const sortedArr2 = arr2.slice().sort();

        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i].currencyCode !== sortedArr2[i].currencyCode) {
                return false;
            }
        }

        return true;
    }

    public removeAdditionalCurrency (currencyToDelete: ICurrencyOptionDto): void {
        this.updatedClientSetup.additionalReportingCurrencies = this.updatedClientSetup.additionalReportingCurrencies.filter(currency =>
            currency.currencyCode !== currencyToDelete.currencyCode
        );
    }

    public addAdditionalCurrency (currencyCodeToAdd : ICurrencyOptionDto) : void {
        this.updatedClientSetup.additionalReportingCurrencies.push(currencyCodeToAdd);
    }

    public discardChanges (): void {
        this.$store.commit('updateUnsavedData', false);
        this.$store.commit('updateShowUnSavedMessage', false);
        this.fetchData();
    }
}
