













































import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseModal from '@/components/BaseModal.vue';
import VTooltip from 'v-tooltip';
import { ICurrencyOptionDto } from '@/interfaces/dto/IClientSetupWithOptionsDto';
import  BaseButton  from '@/components/BaseElements/BaseButton.vue';
import ConfirmDeletion from '@/components/ConfirmDeletion.vue';


Vue.use(VTooltip);

@Component({
    components: {
        BaseModal,
        BaseButton,
        ConfirmDeletion
    },
})
export default class AdditionalReportingCurrencies extends Vue {
    @Prop({ type: Array, required: true }) currencyOptions: ICurrencyOptionDto[]

    @Prop({ type: Array, required: true }) currenciesSelected: ICurrencyOptionDto[]

    public showAdditionalCurrencySelector = false;

    public defaultCurrencyOption : ICurrencyOptionDto | '' = '';

    public newCurrency: ICurrencyOptionDto | '' = '';

    MAX_ADDITIONAL_CURRENCIES_COUNT = 3;

    get currencyCount (): number {
        return this.currenciesSelected.length;
    }

    get enableAddCurrencyButton (): boolean {
        return this.currenciesSelected.length < this.MAX_ADDITIONAL_CURRENCIES_COUNT;
    }

    get getCurrenciesAvailable () : ICurrencyOptionDto[] {
        const selectedCurrenciesSet = new Set(this.currenciesSelected.map(currency => `${currency.currencyCode}`));
        const currenciesNotSelected = this.currencyOptions.filter(currency =>
            !selectedCurrenciesSet.has(`${currency.currencyCode}`)
        );
        return currenciesNotSelected;
    }

    public deleteCurrency (currencyToDelete: ICurrencyOptionDto) : void {
        this.$emit('remove-additional-currency', currencyToDelete);
    }

    public addCurrency () : void {
        if (this.newCurrency !== this.defaultCurrencyOption) {
            this.$emit('add-additional-currency', this.newCurrency);
            this.newCurrency = this.defaultCurrencyOption;
        }

        this.showAdditionalCurrencySelector = false;
    }
}
