import { IClientSetupWithOptionsDto } from '@/interfaces/dto/IClientSetupWithOptionsDto';
import { IClientSetupDto } from '@/interfaces/dto/IClientSetupWithOptionsDto';
import ApiHelper from './apiHelper';
import { IUpdateClientSetupDto } from '@/interfaces/dto/IUpdateClientSetupDto';

export default class ClientSetupApi {
    static async getClientSetupForClient (clientId: string) : Promise<IClientSetupWithOptionsDto> {
        return (await ApiHelper.get(`GetClientSetupForClient/${clientId}`)).data as Promise<IClientSetupWithOptionsDto>;
    }

    static async updateClientSetupForClient (clientId: string, clientSetup: IUpdateClientSetupDto) : Promise<IClientSetupDto> {
        return (await ApiHelper.post('UpdateClientSetupForClient', { clientId: clientId, clientSetup: clientSetup })).data as Promise<IClientSetupDto>;
    }
}